ion-menu::part(backdrop) {
  background-color: rgba(255, 0, 255, 0.5);
}

ion-menu::part(container) {
  border-radius: 0 20px 20px 0;

  box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
}

.mobile_logo {
  width: 100%;
  text-align: center;
  margin: 0px auto;
  display: inline-block;
}

.mobile_logo img {
  width: 50%;
  text-align: center;
}

.mobile_login_btn {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  background: #43b02a;
  color: #fff;
  font-size: 16px;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

.mobile_login_btn_text {
  width: 95%;
  float: left;
}

.mobile_login_btn_arrow {
  width: 5%;
  float: left;
  text-align: right;
}

.mobile_menu_text {
  color: rgba(38, 38, 38, .9);
  font-size: 18px;
  line-height: 26px;
}

.mobile_menu_text span {
  padding-left: 5px;
}

.mobile-menu-bottom {
  margin-top: 30px;
}

ul.mobile_menu li {
  padding: 10px 0px;
}

.offcanvas-menu-one {
  border-top: 1px solid rgba(38, 38, 38, .5);
  margin-top: 20px;
  padding-top: 20px;
}